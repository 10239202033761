import React from 'react'
import PropTypes from 'prop-types'
import { Carousel } from 'react-responsive-carousel'
import { NavLink } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { Tooltip } from 'antd'

import { QuestionCircleOutlined } from '@ant-design/icons'

import Button from '../../Button'
import Amenities from '../../Amenities/Amenities'
import HerHim from '../../../components/HerHim'

import useAbstractionMediaQueries from '../../../hooks/useAbstractionMediaQueries'

import './SwitcherContent.scss'
import { normalizeIdString } from '../../../utils/helpers'

const SwitcherContent = ({ activeRoom, currentSlide, setCurrentSlide }) => {
  const { t } = useTranslation()

  const additionalInfoTexts = {

    'Standard Pool View Room': (
      <>
        {/*t('Rooms.WorkingDays')<br />*/}
        
        {t('Rooms.CheckInInfo')}
        <br />
        {t('Rooms.BreakfastInfo')}
        {/* <br />
        {t('Rooms.BreakfastDiscount')} */}
        <br />
        {t('Rooms.BreakfastTimeframe')}
        <br />
        {t('Rooms.NoDogsFriendly')}
      </>
    ),
    'Standard Forest View Room': (
      <>
        {/*t('Rooms.WorkingDays')<br />*/}
        {t('Rooms.CheckInInfo')}
        <br />
        {t('Rooms.BreakfastInfo')}
        {/* <br />
        {t('Rooms.BreakfastDiscount')} */}
        <br />
        {t('Rooms.BreakfastTimeframe')}
        <br />
        {t('Rooms.NoDogsFriendly')}
      </>
    ),
    'Superior Pool View Room with Terrace/Balcony': (
      <>
        {/*t('Rooms.WorkingDays')<br />*/}
        {t('Rooms.CheckInInfo')}
        <br />
        {t('Rooms.BreakfastInfo')}
        {/* <br />
        {t('Rooms.BreakfastDiscount')} */}
        <br />
        {t('Rooms.BreakfastTimeframe')}
        <br />
        {t('Rooms.NoDogsFriendly')}
      </>
    ),'Superior Pool View Room with Windowsill': (
      <>
        {/*t('Rooms.WorkingDays')<br />*/}
        {t('Rooms.CheckInInfo')}
        <br />
        {t('Rooms.BreakfastInfo')}
        {/* <br />
        {t('Rooms.BreakfastDiscount')} */}
        <br />
        {t('Rooms.BreakfastTimeframe')}
        <br />
        {t('Rooms.NoDogsFriendly')}
      </>
    ),
    'Superior Plus Forest View Room with Terrace Inclusive': (
      <>
        {/*t('Rooms.WorkingDays')<br />*/}
        {t('Rooms.CheckInInfo')}
        <br />
        {t('Rooms.BreakfastInfo')}
        {/* <br />
        {t('Rooms.BreakfastDiscount')} */}
        <br />
        {t('Rooms.BreakfastTimeframe')}
        <br />
        {t('Rooms.DogsFriendly')}
      </>
    ),
    'Superior Plus Forest View Room with Terrace/Balcony': (
      <>
        {/*t('Rooms.WorkingDays')<br />*/}
        {t('Rooms.CheckInInfo')}
        <br />
        {t('Rooms.BreakfastInfo')}
        {/* <br />
        {t('Rooms.BreakfastDiscount')} */}
        <br />
        {t('Rooms.BreakfastTimeframe')}
        <br />
        {t('Rooms.NoDogsFriendly')}
      </>
    ),
    
    'Premium Forest View Room With Terrace/Balcony': (
      <>
        {/*t('Rooms.WorkingDays')<br />*/}
        {t('Rooms.CheckInInfo')}
        <br />
        {t('Rooms.BreakfastInfo')}
        {/* <br />
        {t('Rooms.BreakfastDiscount')} */}
        <br />
        {t('Rooms.BreakfastTimeframe')}
        <br />
        {t('Rooms.NoDogsFriendly')}
      </>
    ),

    'Family Forest View Room with Terrace': (
      <>
        {/*t('Rooms.WorkingDays')<br />*/}
        {t('Rooms.CheckInInfo')}
        <br />
        {t('Rooms.BreakfastInfo')}
        {/* <br />
        {t('Rooms.BreakfastDiscount')} */}
        <br />
        {t('Rooms.BreakfastTimeframe')}
        <br />
        {t('Rooms.DogsFriendly')}
      </>
    ),
    'Forest View Suite': (
      <>
        {/*t('Rooms.WorkingDays')<br />*/}
        {t('Rooms.CheckInInfo')}
        <br />
        {t('Rooms.BreakfastInfo')}
        {/* <br />
        {t('Rooms.BreakfastDiscount')} */}
        <br />
        {t('Rooms.BreakfastTimeframe')}
        <br />
        {t('Rooms.NoDogsFriendly')}
      </>
    ),
    'Family House': (
      <>
        {/*t('Rooms.WorkingDays')<br />*/}
        {t('Rooms.CheckInInfo')}
        <br />
        {t('Rooms.BreakfastInfo')}
        {/* <br />
        {t('Rooms.BreakfastDiscount')} */}
        <br />
        {t('Rooms.BreakfastTimeframe')}
        <br />
        {t('Rooms.DogsFriendlyFree')}
      </>
    ),
  }

  const prices = {
    'Standard Pool View Room': '3900',
    'Standard Forest View Room': '3900',
    'Superior Pool View Room with Terrace/Balcony': '4100',
    'Superior Pool View Room with Windowsill': '4100',
    'Superior Plus Forest View Room with Terrace Inclusive': '5000',
    'Superior Plus Forest View Room with Terrace/Balcony': '5000',
    'Premium Forest View Room With Terrace/Balcony': '6000',
    'Family Forest View Room with Terrace': '6000',
    'Forest View Suite': '7300',
    'Family House': '14500',
  }

  const {
    images,
    name,
    max_adult_occupancy,
    size: { value },
    description,
    amenities,
    code,
    price_per_night
  } = activeRoom

  const { isMobile, isBigTable } = useAbstractionMediaQueries()

  const renderCutSlides = () => {
    return images
      .filter((item, index) => index === 0 || index > 2)
      .map((image, index) => {
        return (
          <div key={image} style={{ height: '100%', flex: 1 }}>
            <img
              style={{
                height: '100%',
                objectFit: 'cover',
                flex: 1,
              }}
              src={image.url}
              alt=""
            />
          </div>
        )
      })
  }

  const renderSlides = () => {
    return images.map((image, index) => {
      return (
        <div key={image} style={{ height: '100%', flex: 1 }}>
          <img
            style={{
              height: '100%',
              objectFit: 'cover',
              flex: 1,
            }}
            src={image.url}
            alt=""
          />
        </div>
      )
    })
  }

  // const renderAmenitiesList = () => {
  //   return amenities.map(({ name, kind }) => {
  //     return icons[kind] ? (
  //       <li key={kind} className="amenities-grid-item">
  //         <div className="amenity-icon-block">
  //           <img src={icons[kind]} />
  //         </div>
  //         <span>{name}</span>
  //       </li>
  //     ) : null
  //   })
  // }

  const getAdditionalInfoText = () => {
    return <p className="additional-info">{additionalInfoTexts[name]}</p>
  }

  const cutSlides = renderCutSlides()
  const slides = renderSlides()
  const aditionalInfoText = getAdditionalInfoText()

  const roomName =
    name === 'Family Classic Forest View Room'
      ? 'Family classic forest view room'
      : name

  return (
    <div className="switcher-content-wrapper">
      <div className="images-wrapper">
        <Carousel
          showArrows={false}
          showThumbs={false}
          autoPlay={false}
          showStatus={false}
          className={isMobile ? 'bottom-middle' : 'top-right'}
          showIndicators
          selectedItem={currentSlide}
          onChange={slide => setCurrentSlide(slide)}
        >
          {isBigTable ? slides : cutSlides}
        </Carousel>
        {images.length >= 2 && (
          <div className="top-images">
            <img src={images[1]?.url} />
            <img src={images[2]?.url} />
          </div>
        )}
      </div>
      <div className="information-wrapper">
        <h1 className="name">{roomName}</h1>
        <p className="size">
          {max_adult_occupancy} {t('Rooms.GuestsNumber')} &#8226; {value} м
          <sup>2</sup>
        </p>
        <p className="description">
          {description}
          <br />
          <br />
          <b>{t('Rooms.SPATitle')}</b>
          <br />
          {t('Rooms.SPAText')}
          <br />
          <HerHim sex="ForHer" />
          <br />
          <HerHim sex="ForHim" />
        </p>
        <p className="price">
          {t('Rooms.PriceFrom')} {/*prices[name]*/} {price_per_night} {t('Rooms.PricePerNight')}
        </p>
        <NavLink to={`/booking/hotel/${normalizeIdString(code)}`}>
          <Button
            buttonId={`room_switcher_book_button_${normalizeIdString(code)}`}
            classNames="btn btn-green"
          >
            {t('Rooms.BookButton')}
          </Button>
        </NavLink>
        {amenities?.length ? (
          <Amenities amenities={amenities} renderType="rooms" />
        ) : null}
        {aditionalInfoText}
      </div>
    </div>
  )
}

SwitcherContent.propTypes = {
  activeRoom: PropTypes.object.isRequired,
}

export default SwitcherContent
